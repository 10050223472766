import './defines';
import upload from 'controllers/upload';
import { ImportLibraryPageObjects } from 'interfaces/app';
import * as PI from 'interfaces/project';
import { MIME_TYPES_BROWSER_SUPPORTED, MIME_TYPES_SHOWN_AS_PNG } from 'settings/filetypes';
import loadImage from 'services/load-image';
import {
	PhotosModule,
	ProductStateModule,
	UploadModule,
} from 'store';
import { url as urlTools } from 'tools';
import {
	Component,
	Prop,
	Vue,
	Watch,
} from 'vue-property-decorator';
import Template from './template.vue';

@Component({})
export default class ImportLibraryItemView extends Vue.extend(Template) {
	@Prop({
		default: false,
		type: Boolean,
	})
	public readonly hasRemove!: boolean;

	@Prop({
		description: 'Defines the objects that are associated with the current page.',
		required: true,
		schema: 'ImportLibraryPageObjects',
		type: Array,
	})
	public readonly pageObjects!: ImportLibraryPageObjects;

	@Prop({
		required: true,
		type: Object,
	})
	public readonly photoModel!: PI.PhotoModel;

	@Prop({
		default: false,
		type: Boolean,
	})
	public readonly showIncludedStatus!: boolean;

	private isMobile = false;

	private mediaQueryList = window.matchMedia('(max-width: 767px)');

	private isLoading = false;

	protected isVisible = false;

	protected observeVisibility = {
		callback: this.visibilityChanged,
		once: true,
	};

	protected url: string | File | null = null;

	protected get completed() {
		return typeof this.photoModel.id !== 'string';
	}

	protected get imageClass() {
		if (this.orientation > 1) {
			return `orientation${this.orientation}`;
		}

		return '';
	}

	protected get isIncluded() {
		return this.pageObjects.find(
			(objectModel) => !!(
				objectModel.type == 'photo'
				&& objectModel.photoid
				&& objectModel.photoid == this.photoModel.id
			),
		);
	}

	private get orientation() {
		return this.photoModel._orientation || -1;
	}

	protected get progress() {
		if (!this.uploadModel) {
			return 100; // Uploading already finished
		}
		if (this.uploadModel.overall) {
			return Math.round((1 - (this.uploadModel.overall - this.uploadModel.queue) / this.uploadModel.overall) * 100);
		}

		return 0;
	}

	protected get showLoader(): boolean {
		return !this.url;
	}

	protected get style() {
		let style = 'background-image: ';

		if (this.url) {
			const url = typeof this.url === 'string'
				&& this.url.indexOf('http') === 0
				? urlTools.appendParameter(
					this.url,
					'noCorsHeader',
				)
				: this.url;
			style += `url(${url});`;
		} else {
			style += 'none;';
		}

		return style;
	}

	protected get textLabel() {
		return this.uploadModel && this.uploadModel.queue === 0
			? this.$t('uploadStatusQueued')
			: this.$t('uploadStatusUploading');
	}

	private get uploadModel() {
		return this.photoModel.externalId
			? UploadModule.find(this.photoModel.externalId)
			: undefined;
	}

	protected created() {
		this.isMobile = this.mediaQueryList.matches;
	}

	protected mounted() {
		if (this.mediaQueryList.addEventListener) {
			this.mediaQueryList.addEventListener(
				'change',
				this.checkMobile,
			);
		} else {
			// Deprecated 'MediaQueryList' API, <Safari 14, IE, <Edge 16
			this.mediaQueryList.addListener(this.checkMobile);
		}
	}

	protected beforeDestroy() {
		if (this.mediaQueryList.removeEventListener) {
			this.mediaQueryList.removeEventListener(
				'change',
				this.checkMobile,
			);
		} else {
			// Deprecated 'MediaQueryList' API, <Safari 14, IE, <Edge 16
			this.mediaQueryList.removeListener(this.checkMobile);
		}
	}

	private checkMobile(event: MediaQueryListEvent) {
		if (event.matches) {
			this.isMobile = true;
		} else {
			this.isMobile = false;
		}
	}

	protected clickItem() {
		this.$emit(
			'clickItem',
			this.photoModel,
		);
	}

	protected remove() {
		ProductStateModule.removePhoto(this.photoModel.id);
	}

	protected retryUpload() {
		if (this.photoModel.full_url) {
			PhotosModule.retryModel(this.photoModel.id).catch(() => {
				// Swallow error: no action required
			});
		} else if (
			this.uploadModel
			&& UploadModule.findError(this.uploadModel.id)
		) {
			upload.retry(this.uploadModel);
		}
	}

	@Watch('photoModel')
	private setUrl() {
		if (this.photoModel
			&& !this.isLoading
		) {
			this.isLoading = true;

			if (this.photoModel._vectorize
				&& typeof this.photoModel.id === 'string'
			) {
				// We need to wait for the server to convert the file to a vector
				return;
			}

			PhotosModule.getModelUrl({
				id: this.photoModel.id,
				resolution: this.isMobile ? 'thumb' : 'low',
			}).then((urlOrFile) => {
				if (typeof this.photoModel.id === 'number'
					|| (this.photoModel._type && MIME_TYPES_BROWSER_SUPPORTED.includes(this.photoModel._type))
				) {
					return loadImage(
						urlOrFile,
						{
							scale: 500,
							forceCopy: true,
							outputFormat: (
								this.photoModel._type && MIME_TYPES_SHOWN_AS_PNG.includes(this.photoModel._type)
									? 'png'
									: 'jpeg'
							),
						},
					).then(({ image }) => image.src);
				}

				return undefined;
			}).then((url) => {
				this.url = url || null;
			}).finally(() => {
				this.isLoading = false;
			});
		}
	}

	protected visibilityChanged(isVisible: boolean) {
		this.isVisible = isVisible;

		if (isVisible
			&& !this.url
		) {
			this.setUrl();
		}
	}
}
